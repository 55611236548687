import React from 'react';
import { parseMoney,fillZeroLeft } from "../../../utils/utils"

let imageBaseRoot = 'https://fast-menu.s3.amazonaws.com/';

const MenulistElmn = ({elmn, zoom}) => {
    const {ingredients,description} = elmn;
    
    return (
   <>
    <div key={elmn.itemId}>
        <img alt={elmn.itemName} width="60px" onClick={() => zoom(elmn.itemName, elmn.ingredients, elmn.description, elmn.itemId)} src={`${imageBaseRoot}P${fillZeroLeft(elmn.itemId,8)}.jpeg`}></img>
        <p>
            <b>
                {elmn.itemName}
            </b>
            <span>
                {elmn.features}
            </span>
        </p>
        <strong>{(elmn.itemPrice < elmn.priceOff) ? parseMoney(elmn.itemPrice) : parseMoney(elmn.priceOff)}</strong>
    </div>
  
    </>
  )
}

export default MenulistElmn