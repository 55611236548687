import React, { useState, useEffect } from 'react';
import MenulistElmn from '../menuListElmn'
const MenuGroups = ({elmn, itms, type, zoom}) => {

    return (
        (type == 'P') ?
        <>
        <section id="menugrupo">
            <div key={elmn.groupId}>
                <strong>
                    {elmn.groupName}
                </strong>
            </div>
        </section>
        <section id="menuitem">
            {
                itms.filter(menu =>
                    menu.itemGroupId == elmn.groupId
                ).map(item =>
                    <MenulistElmn elmn={item} zoom={(t,i,d,x) => zoom(t,i,d,x)} />
                )
            }
        </section>
        </>
        :
        <section id="menuitem">
            {
                itms.map(item =>
                    <MenulistElmn elmn={item} zoom={(t,i,d,x) => zoom(t,i,d,x)} />
                )
            }
        </section>
  )
}

export default MenuGroups