import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from "../../../hooks/useFetch";
import "./style.sass";
import { Spin, Alert } from "../../common/html-accesories";
import MenuGroups from '../../../components/pages/menuGroups';
import { parseMoney, fillZeroLeft } from "../../../utils/utils"
import Navbar from '../navbar';
import MenulistElmnZoom from '../menuListElmnZoom';
import MenulistCategory from '../menuListCategory';

let itemImageBckt = 'https://fast-menu.s3.amazonaws.com/';

const Menulist = () => {
    const { owner } = useParams();
    const bussInfo = JSON.parse(localStorage.getItem('buss'));
    const bussCode = (bussInfo?.bussinesCode != undefined) ? bussInfo?.bussinesCode : '';
    const [ menuOwner, setMenuOwner] = useState((owner != undefined) ? owner : bussCode);
    
    //== alert section
    const [showAlert, setShowAlert] = useState({
        show: false,
        title: "",
        message: "",
        type: "",
      });
    
    const resetAlert = () => {
        setShowAlert({
          show: false,
          title: "",
          message: "",
          type: "",
        });
      };

    const resetMessage = (sec) =>
        setTimeout(() => {
        resetAlert();
    }, sec * 1000);
    //== fin alert section

    //setMenuOwner(bussHash)

    const [items, setItems] = useState([]);
    const [menu, setMenu] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categoryGroups, setCategoryGroups] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [selectedCategoryOption, setSelectedCategoryOption] = useState({
        value: '',
        text: '—TODOS—',
    });
    const [categoryData, setCategoryData] = useState({
        categoryType: 'P',
        categoryPrice: 10,
        categoryId: 1
    });
    const [zoomData, setZoomData] = useState({
        ingredients: '',
        description: '',
        image: ''//`${imageBaseRoot}P${fillZeroLeft(elmn.itemId,8)}.jpeg`
    })
    const [showSpin, setShowSpin] = useState(true)
    const [rspnGetItems, invokeGetItems] = useFetch(
        `menu/${menuOwner}`,
        undefined,
        'GET',
        true,
        () => {
        
            let rspnData = rspnGetItems.data;
            setItems(
                rspnData[0]
            );
            setCategoryOptions(
                rspnData[1].map((item) => ({
                    value: item.categoryCode,
                    text: item.categoryName.toUpperCase(),
                }))
            );
            let localSelectedCategory = 
            rspnData[1].filter(elmn => 
                elmn.categorySelected == 1
            )

            setSelectedCategoryOption(
                localSelectedCategory.map((item) => ({
                    value: item.categoryCode,
                    text: item.categoryName.toUpperCase(),
                }))[0]
            )
            setMenu(
                rspnData[0].filter(elmn =>
                    elmn.categoryCode ==  localSelectedCategory[0].categoryCode
                )
            )
            setCategories(
                rspnData[1]
            )
            setCategoryGroups(
                rspnData[2].map((elmn) =>
                ({
                    groupCategoryId: elmn.groupCategoryId,
                    groupName: elmn.groupName,
                    groupId: elmn.groupId,
                })
                )    
            )
            setShowSpin(false)
        }
    )
    //== handle data section
    
    const handleSelectOption = (name, dataValue) => {
        let {value, text} = dataValue;
        setMenu(
            items.filter(elmn =>
                elmn.categoryCode ==  value
            )
        )
        setCategoryData(
            categories.filter(elmn =>
                elmn.categoryCode == value
            ).map(item =>
                ({
                    categoryType: item.categoryType,
                    categoryPrice: item.categoryPrice,
                    categoryId: item.categoryId
                })
            )[0]
        )
        //setSearchData((prevItem) => ({ ...prevItem, [name]: value })); 
    };
    const handleZoomData = (title,ingred,descrip,itemId) => {
        setZoomData({
            title,
            ingredients: ingred,
            description: descrip,
            image: `${itemImageBckt}P${fillZeroLeft(itemId,8)}.jpeg`
        })

        setShowAlert({
            show: true,
            title: "",
            message: "",
            type: "success",
        })
    };
    return (
        <>
        {(bussCode != '' && bussCode != undefined) ?
            <Navbar />
        :
            ''
        }
        {showSpin &&
            <Spin />
        }
        {showAlert.show && (
            <Alert
            title={zoomData.title}
            message={<MenulistElmnZoom zoomDesc={zoomData} />}
            type={showAlert.type}
            hasBg={true}
            handleClose={() => resetAlert()}
            />
        )}

        <section className="logo-space">
            <img src={`${itemImageBckt}B${menuOwner}.jpg`} alt={bussInfo?.bussinesName} />
        </section>
        <section id='categories'>
            <MenulistCategory catgList={categoryOptions} catgSelected={(n,o) => handleSelectOption(n,o) }  />
        </section>
        <section id="menuprecio">
                {
                    categoryData?.categoryPrice > 0 ?
                    <div>
                        <span>Precio x pack:</span>
                        <strong>{parseMoney(categoryData?.categoryPrice)}</strong>
                    </div>
                    : ''
                }
        </section>
            {
                categoryData?.categoryType == 'P' ?
                    categoryGroups.filter((item) =>
                        item.groupCategoryId == categoryData.categoryId
                    ).map((elmn) =>
                        <MenuGroups elmn={elmn} itms={menu} type={categoryData.categoryType} zoom={(t,i,d,x) => handleZoomData(t,i,d,x)} />
                    )
                :
                    <MenuGroups itms={menu} type={categoryData?.categoryType} zoom={(t,i,d,x) => handleZoomData(t,i,d,x)} />
            }
    </>
  )
}

export default Menulist