import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const BaseEndpoint = 'https://ngybnb3sad.execute-api.us-east-1.amazonaws.com/dev/';

function useFetchData(appRoute, requestBody, method, immediateCall, callBack) {

  const [data, setData] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const navigate = useNavigate();

  function init() {
    setData(undefined)
    setLoading(true)
    setLoading(false)
  }
  useEffect(() => {
    if (typeof data != 'undefined' && loading === false) {
      // console.log('🛫🛫 FIN DE USEFETCH', route)
      if (typeof callBack == 'function') {
        callBack()
      }
    }
  }, [loading])
//console.log('body: ', requestBody );
//console.log('app: ', appRoute );
  // Use this if you want to make an immediate initial call
  useEffect(() => {
    if (immediateCall) {
      load()
    }
  }, []);

  async function load() {
    // console.log('🛫🛫 INICIO DE USEFETCH', route)
    let logn = JSON.parse(localStorage.getItem('logn'));

    init()
    setLoading(true)
    try {
      let headers = {
          "Content-Type": 'application/json',
          "x-access-token" : `${logn?.token}`,
        }

        let axiosConfig = {
          method: method,
          headers: headers,
          url: BaseEndpoint + appRoute,
        }

        if (method.toUpperCase() != 'GET'){
          axiosConfig.data =
              requestBody
        }

        const rspn = await axios.request({...axiosConfig})
        
        setData(rspn.data);
        setError('');

    } catch (errg) {
      if(errg.code === 'ERR_NETWORK'){
        navigate('/login')
      }
      setError(errg)
    }
    setLoading(false)
  }

  return [{ data, loading, error }, load];
}

export default useFetchData;
